.toppings-table {
    .price {
        display: block;
        font-size: 0.8em;
    }
    table {
        border-collapse: collapse;
        border-style: hidden;
      }
      table td {
        border: 1px solid rgba(200, 200, 200, 0.5);
      }
      
    td {
        text-align: center;
        padding: 0.1em 0.4em;
    }
    td.topping-name {
        text-align: right;
        max-width: 200px;
    }
    thead {
        td {
            min-width: 80px;
            padding: 0 0.4em;
        }
    }
    input[type="checkbox"] {
        width: 1em;
        height: 1em;
    }
        
    
}
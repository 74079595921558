.post-add-quick-nav {
  min-height: 100vh;
  margin-bottom: 0;
  .text {
    padding: 2em 0 1em 0;

    font-size: 1.2em;
    font-weight: 600;
  }
  .actions {
    // display: flex;
    // flex-direction: column;

    a {
      display: inline-block;
      padding: 0.5em 0.5em;
      border-radius: 0.3em;
      margin: 0.3em 0;
      margin-right: 0.3em;
      background: rgba(255,255,255,0.5);
      color: black;
      border: 2px solid black;
      text-decoration: none;
    }
  }
}

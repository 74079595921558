.site-notice-wrapper {
  background: url(https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80) no-repeat;
  background-size: cover;
  padding: 1em 0;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  .bg {
    background: rgba(0,0,0,0.5);
    border-radius: 0.5em;
    color: white;
    padding: 1em 0.5em;
  }
  .custom-notice {
    font-size: 1.1em;
    &:before {
      content: "▶︎";
      margin-right: 0.2em;
      display: inline-block;
      color: rgb(222, 244, 64);
    }
  }
  .notice {
    // font-family: $sigFont;
    font-size: 1.2em;
    line-height: 1.1em;
    font-weight: 600;
    max-width: 700px;
    text-shadow: 0px 0px 4px black;
    margin: 0 auto;
    padding: 0.5em 1em;
    // display: flex;

    a {
      // text-decoration: none;
      font-weight: 600;
      // color: $red;
    }
    h1 {
      font-weight: 600;
      margin: 1em 0;
    }

  }
  a {
    // text-decoration: none;
    font-weight: 600;
    text-shadow: 0px 0px 4px black;
    // color: $red;
  }
  h1 {
    font-weight: 600;
    margin: 1em 0;
    text-align: center;
  }
  .hide-button-wrap {
    text-align: center;
    a {
      color: white
    }
    a.view {
      background: #39bb35;
      // color: black;
      text-shadow: none;
      padding: 0.4em 0.5em;
      border-radius: 0.5em;
      display: inline-block;
    }

  }
}

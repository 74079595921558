$white: #f8f8fc;
$black: #280900;
$accent: #268bd2;
$link: #268bd2;
$clickable: #990000;
$background: $white;
$poncitlanRed: #ed0000;
$clickable: $poncitlanRed;
$poncitlanBlue: #2777ab;

.page-title {
	text-align: center;
	font-weight: 600;
	font-size: 1.2em;
}

.catering.page p {
	padding: 0.5em 0.5em;
}

.catering.page .content {
	margin: 0 auto;
	max-width: 100%;
}

.catering.page .content h2,
.catering.page .content p {
	padding: 0 0.5em;
}

.catering.page .content small {
	font-size: 0.8em;
}

.catering.page .content .features-list {
	padding-left: 1.5em;
	list-style: square;
}

.catering.page .photo-grid-product-list {
	justify-content: flex-start;
}

.catering.page .photo-grid-product-list .product {
	position: relative;
	width: 80px;
}

.catering.page .photo-grid-product-list .product .bottom-name {
	border-radius: 0.2em;
}

.catering.page .photo-grid-product-list .product .price {
	display: none;
}

.catering.page .title {
	margin: 0.5em 0;
	font-weight: 800;
	font-size: 1.2em;
	border-left: 5px solid $poncitlanRed;
}

.catering.page .option {
	padding: 1em 0;
	border-bottom: 4px solid $poncitlanBlue;
}

.catering.page .option header h2 {
	text-align: left;
}

.catering.page .tasting-link-wrap {
	z-index: 100;
	position: fixed;
	bottom: 0;
	width: 100%;
	text-align: center;
	padding: 0.4em 0;
	background: $poncitlanBlue;
}

.catering.page .tasting-link-wrap a {
	display: inline-block;
	color: $white;
	text-decoration: none;
	background: #00c500;
	padding: 0.1em 0.5em;
	border-radius: 1em;
}

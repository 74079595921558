.dine-in-option-label {
  margin: 0.5em 0;
  margin-left: 0.2em;
  .find-table {
    margin-left: 1em;
    font-weight: 400;
  }
}
.checkout__dine-in-widget input[type="radio"].dine-in-radio {
  margin-right: 0.2em;
  clip-path: none;
  clip: none; /* IE 6/7 */
  clip: none;
  height: auto;
  width: auto;
  overflow: auto;
  appearance: radio !important;
  -webkit-appearance: radio !important;
  position: relative;
  white-space: nowrap; /* added line */
  // width: 1px;

}
.tab-number-ui {
  margin-left: 0.8em;
  label {
    font-weight: 400;
    margin-bottom: 0.2em;
  }
}

.email-sign-up {
  margin: 1em 0;
  padding: 1em 0;
  line-height: 1.3em;
  .brand-name {
    font-weight: 600
  }
  .subscribe-button {
    background: #00ae00;
    color: white;
  }
  .email-input {
    
    // font-style: italic;
  }
  .features {
    margin: 0;
    padding: 0;
    padding-left: 1em;
    list-style: disc;
    font-size: 0.8em;

    li {

    }
  }

}

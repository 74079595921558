.checkout-tips-section {
  padding: 0.5em 0.1em;
  .hint {
    font-size: 0.9em;
    padding-left: 0.2em;
    font-style: italic;
    margin-bottom: 0.5em;
  }
  input[type="radio"] {
    clip-path: inset(100%);
    clip: rect(1px 1px 1px 1px); /* IE 6/7 */
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; /* added line */
    width: 1px;
  }
  .all-tip-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .options-container {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    border: 2px solid #384048;
    border-radius: 0.2em;
    overflow: hidden;
    // border: 1px solid black;
    margin-right: 0.2em;
    margin-bottom: 0.4em;
    &.primary {

    }
  }
  .tip-option-clickable {
    color: black;
    min-width: 2.7em;
    height: 3em;
    margin: 0;
    &.custom, &.later {
      font-size: 0.9em;
      height: 2em;
      label {
        font-weight: 400;
      }
    }
    &.selected > label {
      background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
      // color: white;
    }
    label {
      padding: 0 0.5em;
      box-sizing: border-box;
      cursor: pointer;
      border-left: 1px solid #384048;
      background: white;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .content {
        line-height: 1.5em;
        font-size: 1.2em;
      }
      .actualAmount {
        color: rgba(0,0,0,0.7);
      }
    }
    .edit-label {
      margin-left: 0.3em;
      font-weight: 400;
      text-decoration: underline;
    }
    .actualAmount {
      font-weight: 400;
      font-size: 0.9em;
    }
    &:first-of-type {
      label {
        border-left: none;
      }
    }
  }

}

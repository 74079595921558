
.rewards-special {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Chewy', Helvetica, sans-serif;
  background: url(https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=850&q=80) no-repeat;
  background-size: cover;
  background-position: center 30%;
  .instructions {
    strong {
      font-weight: 600;
    }
    font-family: Helvetica;
    font-size: 0.8em;
  }
  .header {
    text-align: center;
    font-size: 15vw;
    margin: 1em 0 0.5em 0;
    text-shadow: 2px 2px 5px black;
    color: white;
    line-height: 0.4em;
    small {
      margin-top: 0;
      font-size: 0.3em;
    }
  }
  .card {
    box-shadow: 2px 2px 10px black;
    border: 1px solid white;
    margin: 0em 0.4em 3em 0.4em;
    max-width: 460px;
    padding: 0.1em 0.5em 1em 0.5em;
    background-color: #fff1d2;
    border-radius: 0.2em;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h16v2h-6v6h6v8H8v-6H2v6H0V0zm4 4h2v2H4V4zm8 8h2v2h-2v-2zm-8 0h2v2H4v-2zm8-8h2v2h-2V4z' fill='%23ffffff' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
    line-height: 1.1em;
    h1 {
      font-size: 1.3em;
      font-weight: 600;
    }
    h2 {
      text-align: center;
      margin: 1em 0;
    }
    ul {
      list-style: disc;
      padding-left: 1em;
    }
    li {
      margin-bottom: 1em;
    }
  }
  .message {
    text-align: center;
    color: #fc0006;
    border: 2px solid #fc0006;
    padding: 0.2em;
    border-radius: 0.2em
  }
  .sign-up-form {
    display: flex;
    align-items: baseline;
    justify-content: center;
    input {
      font-size: 0.8em;
      padding: 0.2em 0.2em;
      max-width: 5em;
      margin-right: -0.1em;
      border-radius: 0.2em 0 0 0.2em;
    }
  }
  .sign-up-button {
    color: white;
    padding: 0.3em 1em;
    background-color: #ff7000;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23ffd600' fill-opacity='0.4' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
  }
}

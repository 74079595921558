

.buffet.product-modifiers {

  .buffet-mod.modifier {
    width: 100%;
    .content {
      flex: 1;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .quantity.panel {

      display: flex;
      // flex-wrap: wrap;
      box-shadow: none;
      height: 100%;
      width: 100%;
      margin: 0;
      box-sizing: border-box;
      background: none;
      // .modifier-info-group {
      //   display: flex;
      //   flex-wrap: wrap;
      // }
      .modifier-description {
        font-weight: 400;
        font-size: 0.9em;
        margin-top: 0.1em;
      }
      .price {
        font-weight: 400;
      }
      .thin {
        text-transform: none;
        font-weight: 400;
        font-size: 0.8em;
      }
      .control-group {
        button {
          touch-action: manipulation;
        }
        input {
          width: 2.8em;
        }
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {

          -webkit-appearance: none;
          margin: 0;
        }
        font-size: 1.2em;

        // justify-self: flex-end;
      }
    }
  }
}

.not-found {
  line-height: 1.35em;
  .notice {
    background: #f9f7c1;
    border-radius: 0.2em;
    padding: 2em 1em 6em 1em;
    margin: 2em auto;
    max-width: 460px;
  }
  h1 {
    font-weight: 600;
  }
  ul {
    padding-left: 1em;
    list-style: disc;
  }
}

a.category-link-item {
    color: black !important;
    text-decoration: none;
    font-size: 0.8em;
    font-weight: 400;
    &:visited {
        color: black;
        text-decoration: none;
    }
    &.active {
        text-decoration: underline;
        text-decoration-color: red;
        text-decoration-thickness: 2px;
        text-underline-offset: 6px;
      }
}
nav.menu-navigation::-webkit-scrollbar {
    display: none;

}
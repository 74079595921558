.how-to-install {
  .app-icon-wrapper {
    padding: 1em 0;
    text-align: center;
  }
  .app-icon-frame {
    display: inline-block;
    width: 100px;
  }
  .app-icon {
    display: block;
    width: 100%;
    border-radius: 18px;
  }
  .instructions {
    padding: 0 0.5em;
  }
  .instructions-image-wrapper {
    position: fixed;
    bottom: 0;
    img {
      width: 100%;
    }
  }
  .se-portrait-wrapper {
    // display: none;
    text-align: center;
    img {
      width: 300px;
    }
    width: 100%;
    position: fixed;
    bottom: 0px;
    // bottom: 100px;
  }
  .ipad-instructions-image-wrapper {
    max-width: 500px;
    margin-left: auto;
    padding-right: 110px;
    img {
      width: 100%;
    }
  }
  // ol {
  //   margin: 0.5em 0;
  //   list-style: decimal;
  //   padding-left: 1.5em;
  // }

}


.path__app {
  .cart.minimized {
    display: none;
  }
  .footer {
    display: none;
  }
  &.site-content {

  }
}

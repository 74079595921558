.drive-in-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
  font-size: 20px;
  // background: url(https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80) no-repeat;
  h1 {
    font-weight: 600;
    font-size: 1.5em;
    text-align: center;
    // background: blue;
    // color: white;
    .contact-free {
      padding: 0.3em;
    }
  }
  .drive-in {
    // border: 2px solid black;
    padding: 0.5em;
    padding-left: 1.5em;
    // border-radius: 0.5em;
    // background: white;
    color: white;
    a {
      color: white;
      font-weight: 600;
    }
  }
  .options {

    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
    .item {
      padding: 0.5em 0.3em;
      // background: rgba(0,0,0,0.3);
    }
  }

  .hint {
    font-size: 0.8em;
  }
  h2  {
    font-size: 1.5em;
    font-weight: 600;
  }
  .content {
    text-shadow: 1px 1px 2px black;
    line-height: 1.5em;
  }
  .highlight {
    background: #ffe300;
    display: inline-block;
    padding: 0em 0.4em;
    border-radius: 0.2em;
    color: black;
    text-shadow: none;
  }
  ol {
    list-style: decimal;
    margin-left: 1.5em;
  }
  .header {
    padding: 0.5em 0;
  }
  .tag {
    color: black;
    font-size: 0.8em;
    display: inline-block;
    background: #7bf375;
    border-radius: 0.5em;
    line-height: 1.5em;
    padding: 0 0.2em;
    text-shadow: none;
  }

}
